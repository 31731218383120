<template>
  <div>
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="24">
        <div class="card-container">
          <a-checkbox-group
            @change="selectTaskIds"
            class="card-flex"
            v-model="selected"
          >
            <div class="card-content" v-for="plan in plans" :key="plan.id">
              <a-card :bordered="false" class="taskCard" :title="plan.planName">
                <a-dropdown
                  slot="extra"
                  :trigger="['click']"
                  placement="bottomCenter"
                >
                  <a-icon type="setting" class="setting" />
                  <a-menu slot="overlay">
                    <a-menu-item key="0">
                      <a @click="editPlan(plan)">编辑计划</a>
                    </a-menu-item>
                    <a-menu-item key="1">
                      <a @click="onDeletePlan(plan.id)">删除计划</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
                <div class="top-tab" slot="">
                  <div key="addTask" @click="addTask(plan.id)" class="addtask">
                    <a-icon type="plus-circle" />&nbsp;&nbsp;新增任务
                  </div>
                  <a-progress
                    :percent="(plan.doneTask / plan.totalTask) * 100"
                    :show-info="false"
                  />
                  <div style="margin-bottom: 10px; text-align: right">
                    {{ plan.doneTask }} / {{ plan.totalTask }}
                  </div>
                </div>
                <div class="taskitem">
                  <a-card
                    v-for="task in plan.projectTasks"
                    :key="task.id"
                    :class="{
                      delay: task.isOverDue,
                      finished: task.status == 10,
                    }"
                    class="task-wrap"
                    @click="detail(task.id)"
                  >
                    <div class="taskname">
                      <div style="width: 56%">
                        <a-checkbox :value="task.id" @click.stop>
                          <label>{{ task.name }}</label>
                        </a-checkbox>
                      </div>

                      <div>
                        <label
                          ><a-icon type="user" />&nbsp;{{ task.ownerName }}
                        </label>
                      </div>
                    </div>
                    <div class="expectedDate">
                      预计完成日期:{{
                        task.endDate
                          ? moment(task.endDate).format("YYYY-MM-DD")
                          : ""
                      }}
                      <label class="otherBtn">
                        <a-space size="middle">
                          <!-- <a-icon type="edit" @click.stop="editTask(task)" /> -->
                          <a-icon
                            type="delete"
                            @click.stop="delTask(task.id)"
                          />
                        </a-space>
                      </label>
                    </div>
                    <div v-if="task.isOverDue" style="margin-top: 6px">
                      <span class="delayDay">超时{{ task.overDueDays }}天</span>
                    </div>
                  </a-card>
                </div>
              </a-card>
            </div>

            <div class="card-content" v-if="hasRule">
              <a-card :bordered="false" class="taskCard addNewPlan">
                <label @click="addPlan" class="taskLabel">
                  <a-icon type="plus" /> 新增计划
                </label>
              </a-card>
            </div>
          </a-checkbox-group>
        </div>
      </a-col>
    </a-row>

    <plan-add :projectId="projectId" ref="planadd" @done="getPlans"></plan-add>
    <plan-edit :plan="plan" ref="planedit" @done="getPlans"></plan-edit>

    <task-add
      :planId="planId"
      :projectId="projectId"
      ref="taskadd"
      @done="getPlans"
    ></task-add>
    <task-edit :task="task" ref="taskedit" @done="getPlans"></task-edit>

    <!-- 批量编辑Modal -->
    <a-modal
      v-model="modalBulkEditVisible"
      title="批量编辑"
      :width="400"
      centered
      destroyOnClose
    >
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :loading="false"
          @click="bulkUpdate"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        ref="bulkEditForm"
        :model="bulkEditForm"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="预计开始日期" prop="startDate">
          <a-date-picker
            v-model="bulkEditForm.startDate"
            :disabled-date="disabledTaskStartDate"
            @openChange="handleTaskStartOpenChange"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            type="date"
            placeholder="预计开始日期"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item label="预计完成日期" ref="endDate" prop="endDate">
          <a-date-picker
            v-model="bulkEditForm.endDate"
            :disabled-date="disabledTaskEndDate"
            @openChange="handleTaskEndOpenChange"
            :open="open"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            placeholder="预计完成日期"
            style="width: 100%"
          />
        </a-form-model-item>

        <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
          <a-select v-model="bulkEditForm.ownerId" :allowClear="true">
            <a-select-option v-for="m in members" :key="m.userId">
              {{ m.userName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 筛选Modal -->
    <a-modal
      v-model="modalFillterVisible"
      title="筛选"
      :width="400"
      centered
      destroyOnClose
    >
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="false" @click="search">
          确认
        </a-button>
      </template>
      <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
          <a-select v-model="ownerId" :allowClear="true">
            <a-select-option v-for="m in members" :key="m.userId">
              {{ m.userName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-item label="状态">
          <a-select
            default-value=""
            :value="status"
            :allowClear="true"
            @change="handleChange"
          >
            <a-select-option value="0"> 待启动 </a-select-option>
            <a-select-option value="5"> 进行中 </a-select-option>
            <a-select-option value="8"> 待验收 </a-select-option>
            <a-select-option value="10">已完成 </a-select-option>
            <a-select-option value="-1">已延期 </a-select-option>
          </a-select>
        </a-form-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { getPlanList, deletePlan, getMembers } from "@/api/projectmanage.js";
import { deleteTask, bulkupdateTask } from "@/api/taskManage";
import PlanAdd from "./PlanAdd.vue";
import PlanEdit from "./PlanEdit.vue";
import TaskAdd from "./TaskAdd.vue";
import TaskEdit from "./TaskEdit.vue";
import moment from "moment";
export default {
  components: { PlanAdd, PlanEdit, TaskAdd, TaskEdit },
  name: "ProjectTask",
  props: {
    projectId: {
      type: String,
      require: true,
      default: "",
    },
    hasRule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      moment,
      plans: [],
      plan: {},
      task: {},
      planId: "",
      ownerId: null,
      status: null,
      members: [],
      taskVisible: false,
      bulkEditForm: {
        taskIds: [],
        startDate: null,
        endDate: null,
        ownerId: null,
      },
      modalBulkEditVisible: false,
      modalFillterVisible: false,
      selected: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    show(id) {
      getPlanList({
        projectId: id,
        ownerId: this.ownerId,
        status: this.status,
      }).then((res) => {
        this.plans = res.items;
        console.info(res);
      });
    },
    // 获取计划列表
    getPlans() {
      //debugger;
      getPlanList({
        projectId: this.projectId,
        ownerId: this.ownerId,
        status: this.status,
      }).then((res) => {
        this.plans = res.items;
        console.info(res);
      });
    },

    //==============任务相关============
    disabledTaskStartDate(startValue) {
      const endValue = this.bulkEditForm.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledTaskEndDate(endValue) {
      const startValue = this.bulkEditForm.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleTaskStartOpenChange(open) {
      if (!open) {
        this.open = true;
      }
    },
    handleTaskEndOpenChange(open) {
      this.open = open;
    },
    detail(id) {
      this.$router.push(`/projectManage/task/${id}/${this.projectId}`);
    },
    addPlan() {
      this.$refs.planadd.open();
    },
    editPlan(plan) {
      console.info(plan);
      this.plan = plan;
      this.$refs.planedit.open();
    },
    onDeletePlan(id) {
      let _this = this;
      this.$confirm({
        title: "确定",
        content: "删除当前计划",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          deletePlan(id).then(() => {
            _this.$message.success("删除计划成功");
            _this.getPlans();
          });
        },
        onCancel() {},
      });
    },
    addTask(id) {
      this.planId = id;
      this.$refs.taskadd.open();
    },
    editTask(task) {
      this.task = task;
      this.$refs.taskedit.open();
    },
    //删除任务
    delTask(id) {
      if (id) {
        let _this = this;
        this.$confirm({
          title: "确定",
          content: "删除当前任务",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            deleteTask(id).then((res) => {
              if (res) {
                _this.$message.success("删除任务成功");
                _this.getPlans();
              }
            });
          },
          onCancel() {},
        });
      }
    },
    selectTaskIds(checkedValues) {
      debugger;
      this.isChecked = true;
      this.bulkEditForm.taskIds = checkedValues;
    },
    showMembers() {
      let params = { projectId: this.projectId };
      getMembers(params).then((res) => {
        this.members = res;
      });
    },
    //批量编辑
    bulkUpdate() {
      var params = this.bulkEditForm;
      bulkupdateTask(params).then((res) => {
        if (res) {
          this.$message.success("批量编辑任务成功");
          this.modalBulkEditVisible = false;
          this.selected = [];
          this.getPlans();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(value) {
      this.status = value;
    },
    //筛选
    search() {
      this.getPlans();
      this.modalFillterVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.delay {
  border: red 1px solid;
  background-color: rgba(253, 234, 230, 1);
  border-radius: 2px;
}
.finished {
  background: #ddd;
}
.delayDay {
  background-color: #fdd7d4;
  color: #ff4b51;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}
.task-wrap {
  cursor: pointer;
}
.gutter-row {
  .card-container {
    overflow-y: scroll;
    width: 100%;
    position: relative;
    .ant-card {
      margin-bottom: 20px;
      display: inline-block;
      width: 100%;
    }
    .card-flex {
      display: inline-flex;
    }
    .card-content {
      float: left;
      position: relative;
      margin: 10px;
      .taskCard {
        // background-color: rgb(237, 238, 240);
        //height: calc(100vh - 270px);
        width: 300px;

        // ::v-deep > .ant-card-body {
        //   height: calc(100vh - 350px);
        //   overflow-y: auto;
        // }
        .taskitem {
          width: 100%;
          height: calc(100vh - 350px);
          overflow-y: auto;
          padding: 0 5px;

          .taskname {
            margin-bottom: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            justify-content: space-between;
            > div {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              ::v-deep .ant-checkbox-wrapper {
                display: inline;
              }
            }
          }
        }

        .taskitem::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
        }
      }
      .addNewPlan {
        width: 270px;
        height: 76px;
      }
      .taskLabel {
        color: rgb(64, 169, 255);
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

.taskCard {
  ::v-deep.ant-card-head {
    background: #edeef0;
    border-radius: 2px;
    padding: 0 10px;
    min-height: 30px;
    margin-bottom: 10px;
    border: none;

    .ant-card-head-title {
      padding: 10px 0;
      font-weight: bold;
      color: #333333;
      font-size: 14px;
    }

    .ant-card-extra {
      padding: 10px 0;
      .setting {
        color: #1890ff;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .ant-card-body {
    background: #edeef0;
    border-radius: 2px;
    padding: 10px;

    .addtask {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      .anticon-plus-circle {
        color: #1890ff;
      }
    }
    .taskitem {
      .ant-card-bordered {
        //border: none;

        .ant-card-body {
          background: #ffffff;
          padding: 15px 10px;

          .otherBtn {
            float: right;
            display: none;
          }
        }
        .ant-card-body:hover {
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          box-shadow: 5px 5px 10px #d3d3d3;
          background: #1890ff;

          .taskname {
            color: #ffffff;

            label {
              color: #ffffff;
            }
          }

          .otherBtn {
            display: block;
          }

          .expectedDate {
            color: #ffffff;
          }
        }
      }

      .delay {
        .ant-card-body {
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          background: #fdeae6;
          border-color: #ff4b51;
          border-radius: 2px;
        }
        .ant-card-body:hover {
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          background: #fdeae6;
          box-shadow: none;

          .taskname {
            color: #666666;

            label {
              color: #666666;
            }
          }

          .otherBtn {
            display: block;
          }

          .expectedDate {
            color: #666666;
          }
        }
      }

      .finished {
        .ant-card-body {
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          background: #e4e4e4;
          border-radius: 2px;
        }
        .ant-card-body:hover {
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          background: #e4e4e4;
          box-shadow: none;

          .taskname {
            color: #666666;

            label {
              color: #666666;
            }
          }

          .otherBtn {
            display: block;
          }

          .expectedDate {
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
