<template>
  <!-- 更新计划Modal -->
  <a-modal
    v-model="visible"
    title="编辑计划"
    :width="600"
    centered
    destroyOnClose
  >
    <template slot="footer">
      <a-button key="submit" type="primary" :loading="false" @click="onSubmit">
        确认
      </a-button>
    </template>
    <a-form-model
      ref="planForm"
      :model="planForm"
      :rules="planRules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 19 }"
      v-if="visible"
    >
      <a-form-model-item label="计划名称" ref="planName" prop="planName">
        <a-input v-model="planForm.planName" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updatePlan } from "@/api/projectmanage.js";
export default {
  name: "PlanEdit",
  props: {
    plan: {},
  },
  data() {
    return {
      visible: false,
      planForm: this.plan,
      planRules: {
        planName: [
          { required: true, message: "请输入计划名称", trigger: "blur" },
          {
            min: 2,
            max: 126,
            message: "计划名称长度应在2-126字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    plan: function (val) {
      this.planForm = val;
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    //创建计划弹框提交
    onSubmit() {
      this.$refs.planForm.validate((valid) => {
        if (valid) {
          updatePlan(this.planForm).then((res) => {
            if (res && res.id != "") {
              this.$message.success("更新计划成功");
            }
            this.visible = false;
            this.$emit("done");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
