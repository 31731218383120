<template>
  <div class="bgStyle">
    <a-tabs size="large" v-model="tabKey">
      <a-tab-pane tab="创建项目" disabled></a-tab-pane>
      <a-tab-pane key="1" tab="项目详情">
        <a-form-model
          ref="ruleForm"
          :model="projectForm"
          :rules="rules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-card title="项目详情">
            <a-form-model-item label="项目名称" ref="name" prop="name">
              <a-input v-model="projectForm.name" />
            </a-form-model-item>
            <a-form-model-item label="项目开始日期" prop="startDate">
              <a-date-picker
                v-model="projectForm.startDate"
                :disabled-date="disabledStartDate"
                @openChange="handleStartOpenChange"
                :format="dateFormat"
                :valueFormat="dateFormat"
                placeholder="项目开始日期"
                style="width: 100%"
              />
            </a-form-model-item>
            <a-form-model-item
              label="项目结束日期"
              ref="endDate"
              prop="endDate"
            >
              <a-date-picker
                v-model="projectForm.endDate"
                :disabled-date="disabledEndDate"
                @openChange="handleEndOpenChange"
                :open="open"
                :format="dateFormat"
                placeholder="项目结束日期"
                style="width: 100%"
                :valueFormat="dateFormat"
              />
            </a-form-model-item>
            <a-form-model-item label="项目描述" ref="desc" prop="desc">
              <a-input
                v-model="projectForm.description"
                type="textarea"
                :autoSize="{ minRows: 6, maxRows: 15 }"
                :maxLength=400
              />
            </a-form-model-item>
          </a-card>
          <a-card title="项目成员">
            <a-form-model-item label="负责人" ref="ownerId" prop="ownerId">
              <a-tag color="blue">
                {{ projectForm.ownerName }}
              </a-tag>
            </a-form-model-item>
            <a-form-model-item label="成员" ref="members" prop="members">
              <a-tag color="blue" v-for="m in projectForm.members" :key="m.id">
                {{ m.userName }}
              </a-tag>
              <a-icon
                type="user-add"
                :style="{ fontSize: '24px', color: 'rgb(61,168,245)' }"
                @click="selectMembers"
              />
            </a-form-model-item>
          </a-card>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        tab="项目任务"
        force-render
        :disabled="taskTabVisible"
      >
        <project-task :projectId="projectForm.id" :hasRule="true" ref="task"></project-task>
      </a-tab-pane>
      <a-button
        v-if="tabKey == '1'"
        key="1"
        type="primary"
        slot="tabBarExtraContent"
        :loading="confirmLoading"
        @click="onSubmit"
      >
        下一步
      </a-button>
      <template v-if="tabKey == '2'">
        <div key="1" slot="tabBarExtraContent">
          <a-space>
            <a-button
              key="1"
              type="primary"
              @click="onPrev"
              :loading="confirmLoading"
            >
              上一步
            </a-button>
            <a-button
              key="2"
              type="primary"
              @click="onPublic"
              :loading="confirmLoading"
            >
              发布项目
            </a-button>
          </a-space>
        </div>
      </template>
    </a-tabs>

    <project-member
      :targetKeys="targetKeys"
      :users="users"
      ref="member"
      @done="memberCallback"
    ></project-member>
  </div>
</template>
<script>
import { getCurrentCompanyUsersList } from "@/api/companyuser.js";
import {
  createProject,
  updateProject,
  publicProject,
  templateCreateProject,
} from "@/api/projectmanage.js";
import store from "@/store";
import ProjectMember from "./components/ProjectMember.vue";
import ProjectTask from "./components/ProjectTask.vue";
export default {
  name: "ProjectAdd",
  components: { ProjectMember, ProjectTask },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      disabled: false,
      open: false,
      tabKey: "1",
      taskTabVisible: true,
      modalPlanVisible: false,
      projectForm: {
        name: "",
        startDate: "",
        endDate: "",
        description: "",
        ownerId: "",
        ownerName: "",
        members: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 3,
            max: 126,
            message: "项目长度应在3-126字符之间",
            whitespace: true,
            trigger: "blur",
          },
        ],
        startDate: [
          { required: true, message: "请选择项目开始日期", trigger: "change" },
        ],
        endDate: [
          { required: true, message: "请输入项目结束日期", trigger: "change" },
        ],
      },
      userInfo: {},
      users: [],
      targetKeys: [],
      confirmLoading: false,
    };
  },
  created() {
    this.userInfo = store.getters.getUserInfo;
    this.projectForm.ownerId = this.userInfo.userInfo.id;
    this.projectForm.ownerName = this.userInfo.userInfo.fullName;
    this.projectForm.members.push({
      userId: this.userInfo.userInfo.id,
      userName: this.userInfo.userInfo.fullName,
      isOwner: true,
    });
    this.targetKeys.push(this.userInfo.userInfo.id);
    if (this.$route.params) {
      var params = this.$route.params;
      this.projectForm.name = params.title;
      this.projectForm.description = (params.param || {}).description;
    }
    this.getCurrentCompanyUsersList();
  },
  mounted() {},
  methods: {
    getCurrentCompanyUsersList() {
      getCurrentCompanyUsersList({ isFilterInvitationStatus: true }).then(
        (res) => {
          this.users = [];
          const self = this;
          res.items.forEach((item) => {
            item.key = item.userId.toString();
            item.title = item.fullName;
            item.disabled = (self.projectForm || {}).ownerId === item.userId;
            self.users.push(item);
          });
        }
      );
    },
    disabledStartDate(startValue) {
      const endValue = this.projectForm.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.projectForm.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.open = true;
      }
    },
    handleEndOpenChange(open) {
      this.open = open;
    },
    innerSubmit() {
      if (this.$route.params && this.$route.params.param) {
        var params = this.$route.params;
        var param = JSON.parse(JSON.stringify(this.projectForm));
        param.templateId = params.param.id;
        return templateCreateProject(param).then((res) => {
          if (res && res.id != "") {
            this.$message.success("创建项目成功");
            if (this.$route.params) {
              this.tabKey = "2";
              this.projectForm.id = res.id;
              this.$refs.task.show(res.id);
              this.taskTabVisible = false;
            }
          }
        });
      } else {
        return createProject(this.projectForm).then((res) => {
          if (res && res.id != "") {
            this.$message.success("创建项目成功");
            if (this.$route.params) {
              this.tabKey = "2";
              this.projectForm.id = res.id;
              this.taskTabVisible = false;
            }
          }
        });
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.projectForm.id != undefined && this.projectForm.id != "") {
            updateProject(this.projectForm).then(() => {
              this.$message.success("更新项目成功");
              this.tabKey = "2";
              this.taskTabVisible = false;
              // this.$router.push("/projectManage/index");
              this.confirmLoading = false;
              this.tabKey = "2";
            });
          } else {
            this.innerSubmit()
              .then(() => {
                this.confirmLoading = false;
                // this.$router.push("/projectManage/index");
                this.tabKey = "2";
              })
              .catch(() => {
                this.confirmLoading = false;
              });
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$refs.ruleForm.resetFields();
    },
    selectMembers() {
      this.$refs.member.openModal();
    },
    memberCallback(data) {
      this.projectForm.members = [];
      for (let user of data) {
        this.projectForm.members.push({
          userId: user.userId,
          userName: user.fullName,
          isOwner: user.disabled,
        });
      }
    },
    onPublic() {
      publicProject({ id: this.projectForm.id }).then(() => {
        this.$message.success("项目发布成功");
        // this.$router.push("/projectManage/edit/" + this.projectForm.id + "/1");
        this.$router.push("/projectManage");
      });
    },
    onPrev() {
      this.tabKey = "1";
    },
  },
};
</script>
<style lang="scss" scope>
.ant-tabs-nav .ant-tabs-tab-disabled:first-child {
  font-size: 24px;
  color: rgb(51, 51, 51);
}
.ant-card {
  margin-bottom: 20px;
}
.gutter-row {
  .taskCard {
    background-color: rgb(237, 238, 240);
    min-height: 300px;
  }
  .taskLabel {
    color: rgb(64, 169, 255);
    font-size: 20px;
  }
}
.ant-modal-content {
  .ant-modal-header {
    background-color: rgb(24, 144, 255);
    .ant-modal-title {
      color: white;
    }
  }
  .ant-modal-close-x {
    color: white;
  }
}
</style>
