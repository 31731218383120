import { postRequest, deleteRequest, getRequest } from "@/utils/http";

/**
 * 获取当前用户下公司列表
 * @param {object} parm 员工信息
 * @returns 公司列表
 */
export const getCurrentCompanyList = (parm) =>
  getRequest(`/identity/companyuser/GetCurrentCompanyList`, parm);

/**
 * 获取用户下公司列表
 * @param {object} parm 员工信息
 * @returns 公司列表
 */
export const getUserCompanyList = (parm) =>
  getRequest(`/identity/companyuser/GetUserCompanyList`, parm);

/**
 * 获取当前公司成员信息
 * @param {object} parm 员工信息
 * @returns 公司列表
 */
export const getCurrentCompanyUsersList = (parm) =>
  getRequest(`/identity/companyuser/GetCurrentCompanyUsersList`, parm);

/**
 * 邀请新成员
 * @param {object} parm 员工信息
 * @returns 公司列表
 */
export const createCompanyUser = (parm) =>
  postRequest(`/identity/companyuser/create`, parm);

/**
 * 删除公司关职用户
 * @param {Guid} id 公司成员ID
 * @returns Promise
 */
export const deleteCompanyUsers = (id) => {
  return deleteRequest(`/identity/companyuser/delete/${id}`);
};

/** 员工离职
 * @param {Object} params 交接信息
 */
export const untyingCompanyUser = (parm) =>
  postRequest(`/identity/companyuser/untyings`, parm);

/**
 * 转交公司
 * @param {Guid} id 交接人
 */
export const companyUserTransmit = (id) =>
  postRequest(`/identity/companyuser/transfercompany/${id}`);

/**  再次邀请加入公司
 *  @param {Object} params 邀请信息
 * @returns Promise
 */
export const companyInviteAgain = (params) => {
  return postRequest("/identity/companyuser/inviteAgain", params);
};
