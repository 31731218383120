<template>
  <!-- 编辑任务Modal -->
  <a-modal
    v-model="visible"
    title="修改任务"
    :width="600"
    centered
    @cancel="cancel"
  >
    <template slot="footer">
      <a-button key="submit" type="primary" :loading="false" @click="onSubmit">
        确认
      </a-button>
    </template>
    <a-form-model
      ref="taskForm"
      :model="taskForm"
      :rules="taskRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="任务名称" ref="name" prop="name">
        <a-input v-model="taskForm.name" />
      </a-form-model-item>
      <a-form-model-item label="预计开始日期" prop="startDate">
        <a-date-picker
          v-model="taskForm.startDate"
          :disabled-date="disabledTaskStartDate"
          @openChange="handleTaskStartOpenChange"
          @change="getStartDaysChange"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          type="date"
          placeholder="预计开始日期"
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item label="预计完成日期" ref="endDate" prop="endDate">
        <a-date-picker
          v-model="taskForm.endDate"
          :disabled-date="disabledTaskEndDate"
          @openChange="handleTaskEndOpenChange"
          @change="getTaskDays"
          :open="dtpOpen"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          placeholder="预计完成日期"
          style="width: 100%"
        />
      </a-form-model-item>

      <a-form-model-item label="预计工期" ref="workDays" prop="workDays">
        <a-input v-model="taskForm.workDays" suffix="天" disabled />
      </a-form-model-item>
      <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
        <a-select v-model="taskForm.ownerId" :allowClear="true">
          <a-select-option v-for="m in members" :key="m.userId">
            {{ m.userName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="项目描述" ref="description" prop="description">
        <a-input
          v-model="taskForm.description"
          type="textarea"
          :autoSize="{ minRows: 6, maxRows: 15 }"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getMembers } from "@/api/projectmanage.js";
import { updateTask } from "@/api/taskManage.js";
import moment from "moment";

export default {
  name: "TaskEdit",
  props: {
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    task: {},
  },
  data() {
    return {
      moment,
      dtpOpen: false,
      taskForm: this.task,
      visible: false,
      members: [],
      taskRules: {
        name: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
          {
            min: 3,
            max: 126,
            message: "任务名称长度应在3-126字符之间",
            trigger: "blur",
          },
        ],
        // startDate: [
        //   { required: true, message: "请选择预计开始日期", trigger: "blur" },
        // ],
        // endDate: [
        //   { required: true, message: "请选择预计完成日期", trigger: "blur" },
        // ],
      },
    };
  },
  watch: {
    task: function (val) {
      this.taskForm = JSON.parse(JSON.stringify(val));
      this.taskForm.startDate = val.startDate ? val.startDate : null;
      this.taskForm.endDate = val.endDate ? val.endDate : null;
      this.showMembers();
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },
    showMembers() {
      let params = { projectId: this.taskForm.projectId };
      getMembers(params).then((res) => {
        this.members = res;
      });
    },
    disabledTaskStartDate(startValue) {
      const endValue = this.taskForm.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledTaskEndDate(endValue) {
      const startValue = this.taskForm.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleTaskStartOpenChange(dtpOpen) {
      if (!dtpOpen) {
        this.dtpOpen = true;
      }
    },
    handleTaskEndOpenChange(dtpOpen) {
      this.dtpOpen = dtpOpen;
    },
    getStartDaysChange() {
      if (!this.taskForm.startDate || !this.taskForm.endDate) {
        this.taskForm.workDays = 0;
      }
    },
    //计算任务天数
    getTaskDays() {
      if (!this.taskForm.startDate || !this.taskForm.endDate) {
        this.taskForm.workDays = 0;

        return;
      }
      var sDate1 = new Date(
        moment(this.taskForm.startDate).format("YYYY-MM-DD")
      );
      var sDate2 = new Date(moment(this.taskForm.endDate).format("YYYY-MM-DD"));
      var iDays = parseInt(Math.abs(sDate1 - sDate2) / 1000 / 60 / 60 / 24);
      this.taskForm.workDays = iDays + 1;
    },
    //创建计划弹框提交
    onSubmit() {
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          updateTask(this.taskForm).then((res) => {
            if (res && res.id != "") {
              this.$message.success("修改任务成功");
            }
            this.$emit("done");
            this.visible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
