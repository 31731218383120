import {
  postRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "@/utils/http";

/** 新增图文档 */
export const create = (params) =>
  postRequest(`/production/figureDocument/create`, params);

/** 删除图文档 */
export const deleteFigureDoc = (id) =>
  deleteRequest(`/production/figureDocument/delete/${id}`);

/** 通过id拿到一条图文档数据，只包含最新版本文档项 */
export const getById = (id) =>
  getRequest(`/production/figureDocument/getById/${id}`);

/** 通过图文档项id拿到一条图文档项数据 */
export const getVersionData = (id) =>
  getRequest(`/production/figureDocument/getVersionData/${id}`);

/** 图文档对比(两个不同版本) */
export const versionCompare = (params) =>
  getRequest(`/production/figureDocument/versionCompare`, params);

/** 获取图文档历史记录 */
export const getHistories = (params) =>
  getRequest(`/production/figureDocument/getHistories`, params);

/** 根据条件获取图文档列表 */
export const getList = (params) =>
  getRequest(`/production/figureDocument/getList`, params);

/** 编辑图文档信息 */
export const update = (params) =>
  updateRequest(`/production/figureDocument/update`, params);

/** 上传新版 */
export const uploadNewVersion = (params) =>
  postRequest(`/production/figureDocument/uploadNewVersion`, params);

/** 获取所有图文档类型 */
export const getAllFigureDocTypes = () =>
  getRequest(`/production/figureDocument/getAllFigureDocTypes`);

/** 获取图文档操作日志列表 */
export const getFigureDocOperateLogs = (params) =>
  getRequest(`/production/figureDocument/getFigureDocOperateLogs`, params);

/** 获取所有文档操作类型 */
export const getAllOperateTypes = () =>
  getRequest(`/production/figureDocument/getAllOperateTypes`);

/** (拖拽上传文件)上传到阿里云临时文件夹 */
export const UploadFileToAliyunTempPosition = (params) =>
  postRequest(`/Common/AliyunOss/UploadFileToAliyunTempPosition`, params);

/** 上传到阿里云 */
export const DirectUploadFileToAliyunSpecificPosition = (params) =>
  postRequest(
    `/Common/AliyunOss/DirectUploadFileToAliyunSpecificPosition`,
    params
  );

/* 获取阿里云配置 */
export const getAliyunOssSettings = () =>
  getRequest(`/Common/AliyunOss/GetAliyunOssSettings`);

/** 下载文件接口 */
export const DownLoadAliyunFile = (params) =>
  postRequest(`/Common/AliyunOss/DownLoadAliyunFile`, params);

/** 获取某条图文档数据的最新的版本号+1 */
export const getFigureDocDataVersion = (id) =>
  getRequest(`/production/figureDocument/getFigureDocDataVersion/${id}`);

/**获取任务可关联列表 */
export const getTaskList = (params) =>
  postRequest(`/production/figureDocument/task`, params);

/**写入一条图文档下载操作日志 */
export const addDownLoadFigureDocOperateLog = (params) =>
  postRequest(
    `/production/figureDocument/addDownLoadFigureDocOperateLog`,
    params
  );

/**根据CodeKeyPath查询 */
export const getByCodeKeyPath = (codeKeyPath) =>
  getRequest(`/Common/code/getByCodeKeyPath/${codeKeyPath}`);

/** 批量获取文档项 */
export const getManyItems = (params) =>
  postRequest(`/production/figureDocument/items/getMany`, params);
/** 查询可绑定图文档 */
export const query = (params) =>
  postRequest(`/production/figureDocument/query`, params);

/**获取任务列表用的版本 */
export const getTaskVersions = (param) =>
  postRequest(`/production/figureDocument/task/versions`, param);
