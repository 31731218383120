<template>
  <!-- 项目成员Modal -->
  <a-modal v-model="visible" title="添加成员" :width="800" centered>
    <template slot="footer">
      <a-button key="submit" type="primary" :loading="false" @click="submit">
        确认
      </a-button>
    </template>
    <a-transfer
      :data-source="users"
      :target-keys="selectUsers"
      :show-search="true"
      :filter-option="
        (inputValue, item) => item.title.indexOf(inputValue) !== -1
      "
      :show-select-all="true"
      @change="onChange"
      :titles="['可选择人员', '已选择人员']"
      :operations="['选择', '取消']"
    >
      <template
        slot="children"
        slot-scope="{
          props: {
            direction,
            filteredItems,
            selectedKeys,
            disabled: listDisabled,
          },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          :row-selection="
            getRowSelection({
              disabled: listDisabled,
              selectedKeys,
              itemSelectAll,
              itemSelect,
            })
          "
          :columns="direction === 'left' ? leftColumns : rightColumns"
          :data-source="filteredItems"
          size="small"
          :style="{ pointerEvents: listDisabled ? 'none' : null }"
          :custom-row="
            ({ key, disabled: itemDisabled }) => ({
              on: {
                click: () => {
                  if (itemDisabled || listDisabled) return;
                  itemSelect(key, !selectedKeys.includes(key));
                },
              },
            })
          "
        />
      </template>
    </a-transfer>
  </a-modal>
</template>
<script>
export default {
  name: "ProjectMember",
  props: {
    targetKeys: {
      type: Array,
      require: true,
      default: () => [],
    },
    users: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      leftColumns: [
        {
          dataIndex: "fullName",
          title: "名称",
        },
      ],
      rightColumns: [
        {
          dataIndex: "fullName",
          title: "名称",
        },
      ],
      disabled: false,
      selectUsers: this.targetKeys,
      visible: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    openModal() {
      this.visible = true;
    },
    onChange(nextTargetKeys) {
      this.selectUsers = nextTargetKeys;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          itemSelectAll(treeSelectedKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    submit() {
      this.visible = false;
      let _data = [];
      for (let id of this.selectUsers) {
        for (let user of this.users) {
          if (id == user["key"]) {
            _data.push(user);
          }
        }
      }
      this.$emit("done", _data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
