var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"添加成员","width":800,"centered":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary","loading":false},on:{"click":_vm.submit}},[_vm._v(" 确认 ")])],1),_c('a-transfer',{attrs:{"data-source":_vm.users,"target-keys":_vm.selectUsers,"show-search":true,"filter-option":function (inputValue, item) { return item.title.indexOf(inputValue) !== -1; },"show-select-all":true,"titles":['可选择人员', '已选择人员'],"operations":['选择', '取消']},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function(ref){
      var ref_props = ref.props;
      var direction = ref_props.direction;
      var filteredItems = ref_props.filteredItems;
      var selectedKeys = ref_props.selectedKeys;
      var listDisabled = ref_props.disabled;
      var ref_on = ref.on;
      var itemSelectAll = ref_on.itemSelectAll;
      var itemSelect = ref_on.itemSelect;
return [_c('a-table',{style:({ pointerEvents: listDisabled ? 'none' : null }),attrs:{"row-selection":_vm.getRowSelection({
            disabled: listDisabled,
            selectedKeys: selectedKeys,
            itemSelectAll: itemSelectAll,
            itemSelect: itemSelect,
          }),"columns":direction === 'left' ? _vm.leftColumns : _vm.rightColumns,"data-source":filteredItems,"size":"small","custom-row":function (ref) {
                var key = ref.key;
                var itemDisabled = ref.disabled;

                return ({
            on: {
              click: function () {
                if (itemDisabled || listDisabled) { return; }
                itemSelect(key, !selectedKeys.includes(key));
              },
            },
          });
      }}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }