<template>
  <!-- 添加任务Modal -->
  <a-modal
    v-model="visible"
    title="添加任务"
    :width="600"
    centered
    destroyOnClose
    @cancel="cancel"
  >
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="false"
        @click="taskSubmit"
      >
        确认
      </a-button>
    </template>
    <a-form-model
      ref="taskForm"
      :model="taskForm"
      :rules="taskRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="任务名称" ref="name" prop="name">
        <a-input v-model="taskForm.name" :autoFocus="true" ref="refname" />
      </a-form-model-item>
      <a-form-model-item label="预计开始日期" prop="startDate">
        <a-date-picker
          v-model="taskForm.startDate"
          :disabled-date="disabledTaskStartDate"
          @openChange="handleTaskStartOpenChange"
          @change="getStartDaysChange"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          type="date"
          placeholder="预计开始日期"
          style="width: 100%"
        />
      </a-form-model-item>
      <a-form-model-item label="预计完成日期" ref="endDate" prop="endDate">
        <a-date-picker
          v-model="taskForm.endDate"
          :disabled-date="disabledTaskEndDate"
          @openChange="handleTaskEndOpenChange"
          @change="getTaskDays"
          :open="dtpOpen"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          placeholder="预计完成日期"
          style="width: 100%"
        />
      </a-form-model-item>

      <a-form-model-item label="预计工期" ref="workDays" prop="workDays">
        <a-input v-model="taskForm.workDays" suffix="天" disabled />
      </a-form-model-item>
      <a-form-model-item label="责任人" ref="ownerId" prop="ownerId">
        <a-select v-model="taskForm.ownerId" :allowClear="true">
          <a-select-option v-for="m in members" :key="m.userId">
            {{ m.userName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="项目描述" ref="description" prop="description">
        <!-- <a-input
          v-model="taskForm.description"
          type="textarea"
          :autoSize="{ minRows: 6, maxRows: 15 }"
        /> -->
        <div ref="editorAdd"></div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
/* eslint-disable no-unused-vars */
import { getMembers } from "@/api/projectmanage.js";
import { createTask } from "@/api/taskManage.js";
import moment from "moment";
import { DirectUploadFileToAliyunSpecificPosition } from "@/api/figureDocument.js";
import E from "wangeditor";

export default {
  name: "TaskAdd",
  props: {
    planId: {
      type: String,
      require: true,
      default: "",
    },
    projectId: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    return {
      moment,
      visible: false,
      dtpOpen: false,
      taskForm: {
        name: "",
        workDays: 0,
        planId: this.planId,
        startDate: null,
        endDate: null,
        description: "",
      },
      members: [],
      taskRules: {
        name: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
          {
            min: 3,
            max: 126,
            message: "任务名称长度应在3-126字符之间",
            trigger: "blur",
          },
        ],
        description: [
          {
            max: 4000,
            message: "任务描述最大长度为4000",
            trigger: "blur",
          },
        ],
        editorAdd: null,
        // startDate: [
        //   { required: true, message: "请选择预计开始日期", trigger: "blur" },
        // ],
        // endDate: [
        //   { required: true, message: "请选择预计完成日期", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    open() {
      this.visible = true;
      let params = { projectId: this.projectId };
      getMembers(params).then((res) => {
        this.members = res;

        this.initEditor();
      });
    },
    initEditor() {
      this.taskForm.description = "";

      this.editorAdd = new E(this.$refs.editorAdd);
      this.editorAdd.customConfig = this.editorAdd.customConfig
        ? this.editorAdd.customConfig
        : this.editorAdd.config;
      this.editorAdd.customConfig.onchange = (html) => {
        if (html && html.length > 3900) {
          this.$message.warning("字数超过限制！");
          this.editorAdd.cmd.do("undo");
        } else {
          this.taskForm.description = html;
        }
      };

      this.editorAdd.config.customUploadImg = function (
        resultFiles,
        insertImgFn
      ) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        console.log("resultFiles", resultFiles);
        const formData = new FormData();
        resultFiles.forEach((file) => {
          formData.append("files[]", file);
        });
        DirectUploadFileToAliyunSpecificPosition(formData).then((res) => {
          console.log("DirectUploadFileToAliyunSpecificPosition", res);
          // 上传图片，返回结果，将图片插入到编辑器中
          res.forEach((item) => {
            insertImgFn(item.fileAliyunOssLink);
          });
        });
      };
      // 隐藏“网络图片”tab
      this.editorAdd.customConfig.showLinkImg = false;
      this.editorAdd.config.zIndex = 5;
      this.editorAdd.config.menus = ["image", "undo", "redo"];
      this.editorAdd.config.historyMaxSize = 5;
      this.editorAdd.create();

      this.$refs.refname.focus();
    },
    cancel() {
      this.visible = false;
    },
    disabledTaskStartDate(startValue) {
      const endValue = this.taskForm.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledTaskEndDate(endValue) {
      const startValue = this.taskForm.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleTaskStartOpenChange(dtpOpen) {
      if (!dtpOpen) {
        this.dtpOpen = true;
      }
    },
    handleTaskEndOpenChange(dtpOpen) {
      this.dtpOpen = dtpOpen;
    },
    getStartDaysChange() {
      if (!this.taskForm.startDate || !this.taskForm.endDate) {
        this.taskForm.workDays = 0;
      }
    },
    //计算任务天数
    getTaskDays() {
      if (!this.taskForm.startDate || !this.taskForm.endDate) {
        this.taskForm.workDays = 0;

        return;
      }
      var sDate1 = new Date(
        moment(this.taskForm.startDate).format("YYYY-MM-DD")
      );
      var sDate2 = new Date(moment(this.taskForm.endDate).format("YYYY-MM-DD"));
      var iDays = parseInt(Math.abs(sDate1 - sDate2) / 1000 / 60 / 60 / 24);
      this.taskForm.workDays = iDays + 1;
    },
    //添加任务弹框提交
    taskSubmit() {
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          createTask(this.taskForm).then((res) => {
            if (res && res.id != "") {
              console.log("createTaskSuccess:", res);
              this.$message.success("创建任务成功");
              this.visible = false;
              this.taskForm.name = "";
              this.$emit("done");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {
    planId: {
      handler() {
        this.taskForm.projectId = this.projectId;
        this.taskForm.planId = this.planId;
      },
    },
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    if (this.editorAdd) {
      this.editorAdd.destroy();
      this.editorAdd = null;
    }
  },
};
</script>
<style lang="scss" scoped></style>
